const getSurveyId = (surveyName) => {
  let surveyID = ''

  switch (surveyName) {
    case 'DEQ_HABITS_PREFERENCE':
      surveyID = process.env.DEQ_HABITS_PREFERENCE
      break
    case 'DEQ_MASTER':
      surveyID = process.env.DEQ_MASTER
      break
    case 'DEQ_DOWNY_UNSTOPABLES_SAMPLE':
      surveyID = process.env.DEQ_DOWNY_UNSTOPABLES_SAMPLE
      break
    case 'DEQ_CASCADE_PLATINUM_PLUS_SAMPLE':
      surveyID = process.env.DEQ_CASCADE_PLATINUM_PLUS_SAMPLE
      break
    case 'DEQ_SURVEY_TELL_ME_ABOUT_YOURSELF':
      surveyID = process.env.DEQ_SURVEY_TELL_ME_ABOUT_YOURSELF
      break
    case 'QUIZ_SUSTANIBILITY':
      surveyID = process.env.QUIZ_SUSTANIBILITY
      break
    case 'QUIZ_ACTIVIST':
      surveyID = process.env.QUIZ_ACTIVIST
      break
    case 'QUIZ_WATER':
      surveyID = process.env.QUIZ_WATER
      break
    case 'QUIZ_VOTING':
      surveyID = process.env.QUIZ_VOTING
      break
    case 'QUIZ_HAND_WASHING':
      surveyID = process.env.QUIZ_HAND_WASHING
      break
    case 'QUIZ_GIVING_BACK':
      surveyID = process.env.QUIZ_GIVING_BACK
      break
    case 'QUIZ_CHORE_GAP':
      surveyID = process.env.QUIZ_CHORE_GAP
      break
    case 'QUIZ_BLACK_HISTORY':
      surveyID = process.env.QUIZ_BLACK_HISTORY
      break
    case 'QUIZ_GENDER_EQUALITY':
      surveyID = process.env.QUIZ_GENDER_EQUALITY
      break
    case 'QUIZ_SUSTAINABILITY_MYTH':
      surveyID = process.env.QUIZ_SUSTAINABILITY_MYTH
      break
    case 'QUIZ_HISPANIC_CONTRIBUTIONS':
      surveyID = process.env.QUIZ_HISPANIC_CONTRIBUTIONS
      break
    case 'QUIZ_PRIDE_KNOWLEDGE':
      surveyID = process.env.QUIZ_PRIDE_KNOWLEDGE
      break
    case 'QUIZ_OLYMPIC_KNOWLEDGE':
      surveyID = process.env.QUIZ_OLYMPIC_KNOWLEDGE
      break
    case 'QUIZ_LEAD_WITH_LOVE':
      surveyID = process.env.QUIZ_LEAD_WITH_LOVE
      break
    case 'QUIZ_THIS_OR_THAT':
      surveyID = process.env.QUIZ_THIS_OR_THAT
      break
    case 'QUIZ_CARBON_EMISSIONS':
      surveyID = process.env.QUIZ_CARBON_EMISSIONS
      break
    case 'QUIZ_NATIVE_AMERICAN':
      surveyID = process.env.QUIZ_NATIVE_AMERICAN
      break
    case 'QUIZ_HOW_GRATEFUL':
      surveyID = process.env.QUIZ_HOW_GRATEFUL
      break
    case 'QUIZ_20_DO_YOU_LEAD_WITH_LOVE':
      surveyID = process.env.QUIZ_20_DO_YOU_LEAD_WITH_LOVE
      break
    case 'INNOVATION_SWEEPS_1_HOLIDAY_HOME_CLEANING':
      surveyID = process.env.INNOVATION_SWEEPS_1_HOLIDAY_HOME_CLEANING
      break
    case 'INNOVATION_SWEEPS_2_CLEAN_COZY':
      surveyID = process.env.INNOVATION_SWEEPS_2_CLEAN_COZY
      break
    case 'INNOVATION_SWEEPS_3_NEW_YEAR_NEW_YOU':
      surveyID = process.env.INNOVATION_SWEEPS_3_NEW_YEAR_NEW_YOU
      break
    case 'INNOVATION_SWEEPS_4_ACTIVE_WOMEN':
      surveyID = process.env.INNOVATION_SWEEPS_4_ACTIVE_WOMEN
      break
    case 'QUIZ_19_BRAND_INNOVATION':
      surveyID = process.env.QUIZ_19_BRAND_INNOVATION
      break
    case 'DEQ_SURVEY_TAPJOY':
      surveyID = process.env.DEQ_SURVEY_TAPJOY
      break
    case 'DEQ_3_GIFT_CARD':
      surveyID = process.env.DEQ_3_GIFT_CARD
      break
    case 'DEQ_SURVEY_MR_CLEAN_MAGIC_ERASER':
      surveyID = process.env.DEQ_SURVEY_MR_CLEAN_MAGIC_ERASER
      break
    case 'DEQ_COMPLETE_PROFILE_2_0':
      surveyID = process.env.DEQ_COMPLETE_PROFILE_2_0
      break
    // case 'DEQ_SAMPLE_APDO_MALE_BOX':
    //   surveyID = process.env.DEQ_SAMPLE_APDO_MALE_BOX
    //   break
    // case 'DEQ_SAMPLE_APDO_FAMILY_BOX':
    //   surveyID = process.env.DEQ_SAMPLE_APDO_FAMILY_BOX
    //   break
    case 'QUIZ_21_HOW_KIND_ARE_YOU':
      surveyID = process.env.QUIZ_21_HOW_KIND_ARE_YOU
      break
    case 'QUIZ_22_TEST_YOUR_KNOWLEDGE':
      surveyID = process.env.QUIZ_22_TEST_YOUR_KNOWLEDGE
      break
    case 'QUIZ_23_WILDLIFE_HERO':
      surveyID = process.env.QUIZ_23_WILDLIFE_HERO
      break
    case 'QUIZ_24_SUSTAINABILITY_AT_HOME':
      surveyID = process.env.QUIZ_24_SUSTAINABILITY_AT_HOME
      break
    case 'QUIZ_25_ASIAN_AMERICAN_PACIFIC_ISLANDER':
      surveyID = process.env.QUIZ_25_ASIAN_AMERICAN_PACIFIC_ISLANDER
      break
    case 'QUIZ_26_HOW_COLORFUL_IS_YOUR_PRIDE':
      surveyID = process.env.QUIZ_26_HOW_COLORFUL_IS_YOUR_PRIDE
      break
    case 'QUIZ_27_CHORE_GAP':
      surveyID = process.env.QUIZ_27_CHORE_GAP
      break
    case 'QUIZ_28_ARE_YOU_PREPARED_FOR_DISASTER':
      surveyID = process.env.QUIZ_28_ARE_YOU_PREPARED_FOR_DISASTER
      break
    case 'QUIZ_29_SWIFFER_HELP_TACKLE_YOUR_CREEPY_CRAWLIES':
      surveyID = process.env.QUIZ_29_SWIFFER_HELP_TACKLE_YOUR_CREEPY_CRAWLIES
      break
    case 'QUIZ_30_ARE_YOU_A_HAIRCARE_SUPERHERO':
      surveyID = process.env.QUIZ_30_ARE_YOU_A_HAIRCARE_SUPERHERO
      break
    case 'QUIZ_31_JUST_THE_FACTS_WITH_KATIE_COURIC':
      surveyID = process.env.QUIZ_31_JUST_THE_FACTS_WITH_KATIE_COURIC
      break
    case 'QUIZ_32_HEALTHY_HABITS_THIS_OR_THAT':
      surveyID = process.env.QUIZ_32_HEALTHY_HABITS_THIS_OR_THAT
      break
    case 'QUIZ_33_THE_TRUTH_ABOUT_MATERNAL_HEALTH':
      surveyID = process.env.QUIZ_33_THE_TRUTH_ABOUT_MATERNAL_HEALTH
      break
    case 'QUIZ_34_WHAT_IS_PERIOD_POVERTY':
      surveyID = process.env.QUIZ_34_WHAT_IS_PERIOD_POVERTY
      break
    case 'QUIZ_35_20_MILLION_WAYS_YOUVE_MADE_A_DIFFERENCE':
      surveyID = process.env.QUIZ_35_20_MILLION_WAYS_YOUVE_MADE_A_DIFFERENCE
      break
    case 'QUIZ_36_THE_TRUTH_ABOUT_TREES':
      surveyID = process.env.QUIZ_36_THE_TRUTH_ABOUT_TREES
      break
    case 'QUIZ_37_SPRING_CLEANING':
      surveyID = process.env.QUIZ_37_SPRING_CLEANING
      break
    case 'QUIZ_38_HOW_COLORFUL_IS_YOUR_PRIDE':
      surveyID = process.env.QUIZ_38_HOW_COLORFUL_IS_YOUR_PRIDE
      break
    case 'QUIZ_39_SUMMER_SELF_CARE_ESSENTIAL':
      surveyID = process.env.QUIZ_39_SUMMER_SELF_CARE_ESSENTIAL
      break
    case 'QUIZ_40_BACK_TO_SCHOOL':
      surveyID = process.env.QUIZ_40_BACK_TO_SCHOOL
      break
    case 'QUIZ_41_ORAL_CARE_HISPANIC_STAR':
      surveyID = process.env.QUIZ_41_ORAL_CARE_HISPANIC_STAR
      break
    case 'QUIZ_42_OCT_2023_SWIFFER_HELP_TACKLE':
      surveyID = process.env.QUIZ_42_OCT_2023_SWIFFER_HELP_TACKLE
      break
    case 'QUIZ_43_FIND_YOUR_PG_STANDOUTS_THIS_SEASON':
      surveyID = process.env.QUIZ_43_FIND_YOUR_PG_STANDOUTS_THIS_SEASON
      break
    case 'QUIZ_44_WHAT_DO_YOU_KNOW_ABOUT_DAWN':
      surveyID = process.env.QUIZ_44_WHAT_DO_YOU_KNOW_ABOUT_DAWN
      break
    case 'QUIZ_45_HEALTHY_HABITS_THIS_OR_THAT':
      surveyID = process.env.QUIZ_45_HEALTHY_HABITS_THIS_OR_THAT
      break
    case 'QUIZ_46_PRODUCT_MATCHMAKER':
      surveyID = process.env.QUIZ_46_PRODUCT_MATCHMAKER
      break
    case 'QUIZ_47_PG_PRODUCT_IS_YOUR_SPRING_CLEANING_MUST_HAVE':
      surveyID = process.env.QUIZ_47_PG_PRODUCT_IS_YOUR_SPRING_CLEANING_MUST_HAVE
      break
    case 'QUIZ_48_FIND_YOUR_PERFECT_MATCH_FOR_SUSTAINABLE_LIVING':
      surveyID = process.env.QUIZ_48_FIND_YOUR_PERFECT_MATCH_FOR_SUSTAINABLE_LIVING
      break
    case 'QUIZ_49_WHAT_IS_YOUR_SUMMER_SELF_CARE_ESSENTIAL':
      surveyID = process.env.QUIZ_49_WHAT_IS_YOUR_SUMMER_SELF_CARE_ESSENTIAL
      break
    case 'QUIZ_50_BACK_TO_SCHOOL_READY_WITH_PG':
      surveyID = process.env.QUIZ_50_BACK_TO_SCHOOL_READY_WITH_PG
      break
    case 'QUIZ_51_GET_COZY_WITH_OUR_PG_PRODUCT_PICKS_FOR_FALL':
      surveyID = process.env.QUIZ_51_GET_COZY_WITH_OUR_PG_PRODUCT_PICKS_FOR_FALL
      break
    case 'QUIZ_52_CAN_SWIFFER_HELP_TACKLE_YOUR_CREEPY_CRAWLIES':
      surveyID = process.env.QUIZ_52_CAN_SWIFFER_HELP_TACKLE_YOUR_CREEPY_CRAWLIES
      break
    case 'QUIZ_53_FIND_YOUR_PG_STANDOUTS_THIS_SEASON':
      surveyID = process.env.QUIZ_53_FIND_YOUR_PG_STANDOUTS_THIS_SEASON
      break
    case 'DEQ_GAIN_UDO':
      surveyID = process.env.DEQ_GAIN_UDO
      break
    case 'QUIZ_54_COZY_UP_WITH_PG_FIND_YOUR_PERSONALIZED_COLD_WEATHER_ESSENTIALS':
      surveyID = process.env.QUIZ_54_COZY_UP_WITH_PG_FIND_YOUR_PERSONALIZED_COLD_WEATHER_ESSENTIALS
      break
    case 'QUIZ_55_HEALTHY_HABITS_PG_PRODUCTS_THIS_OR_THAT':
      surveyID = process.env.QUIZ_55_HEALTHY_HABITS_PG_PRODUCTS_THIS_OR_THAT
      break
    case 'QUIZ_56_PLAY_PG_PRODUCT_MATCHMAKER':
      surveyID = process.env.QUIZ_56_PLAY_PG_PRODUCT_MATCHMAKER
      break
    case 'QUIZ_57_HOW_MUCH_DO_YOU_KNOW_ABOUT_WATER':
      surveyID = process.env.QUIZ_57_HOW_MUCH_DO_YOU_KNOW_ABOUT_WATER
      break
    case 'QUIZ_58_SPRING_CLEANING_MUST_HAVE':
      surveyID = process.env.QUIZ_58_SPRING_CLEANING_MUST_HAVE
      break
    case 'QUIZ_59_PERFECT_MATCH_FOR_SUSTAINABLE_LIVING':
      surveyID = process.env.QUIZ_59_PERFECT_MATCH_FOR_SUSTAINABLE_LIVING
      break
    case 'downy-unstopables-sample':
      surveyID = process.env.DEQ_SURVEY_TELL_ME_ABOUT_YOURSELF
      break
    case 'cascade-platinum-plus-sample':
      surveyID = process.env.DEQ_CASCADE_PLATINUM_PLUS_SAMPLE
      break
    case 'DEQ_SAMPLE_SWIFFER_POWERMOP':
      surveyID = process.env.DEQ_SAMPLE_SWIFFER_POWERMOP
      break
    case 'OLYMPIC_VOTENOW_SURVEY':
      surveyID = process.env.OLYMPIC_VOTENOW_SURVEY
      break
    default:
      break
  }

  return surveyID
}

export default getSurveyId

