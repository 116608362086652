import LoginStore from '../components/UserManagement/Login-store'
import Segmanta from '../components/Segmanta/Segmanta'
import {
  loginContextText,
  charityName,
  defaultCharity,
  profileTypeCodes,
} from '../constants/texts'
import aimiaStore from '../containers/AIMIA/aimia.store'
import {
  tiggerSurveyStartEvent,
  triggerSurveyCompleteEvent,
} from './gaEvent.helper'
import {
  fetchPointsBalanceApi,
  clearSessionCharityPoints,
  handleCheckRewardTransactionType,
} from './api.helper'
import { storageAvailable } from '../adapters/serverside.adapters'
import { formatDateWithHyphen } from '../containers/ReceiptScan/DatePicker/date.helper'
import { standardEventForSurveyStart } from '../containers/AuthContainer/standardEventErrorLog'
import { STATUS_1215, STATUS_1216, STATUS_1217 } from '../constants/status-code'

let surveyGoalReachedCallbackLoaded = false

export const segmantaMetaData = (profile) => {
  try {
    const profileData = profile || LoginStore.profileData
    const charityID = profileData.traitCharitableCause.traitValue === null
      || profileData.traitCharitableCause.traitValue === undefined
      || profileData.traitCharitableCause.traitValue === '018GPK'
      || profileData.traitCharitableCause.traitValue === 'BonusActofGood'
      ? defaultCharity
      : profileData.traitCharitableCause.traitValue
    if (profileData) {
      const metaData = {
        sgmnt_ceids: [
          {
            collection: 'users',
            encoding: 'none',
            id: profileData?.consumerId || profileData?.uuid,
            type: 'consumerId',
          },
          {
            collection: 'users',
            encoding: 'none',
            id: profileData?.marketingProgramNbr,
            type: 'marketingProgramNumber',
          },
          {
            collection: 'users',
            encoding: 'none',
            id: 'USA',
            type: 'countryCode',
          },
        ],
        aimia_tcf: [
          {
            CustomFieldExternalReference: 'CharityId',
            CustomFieldStorageValue: {
              DropDownValue: charityID,
            },
          },
        ],
        seg_erid: profileData?.consumerId || profileData?.uuid,
        ConsumerId: profileData?.consumerId || profileData?.uuid,
        Gender: profileData?.gender,
        DOB: profileData?.birthday ? true : null,
        Zip: profileData?.address?.zip ? true : null,
        CharityId: charityID,
        CharityName: charityName[charityID],
      }
      if (typeof window !== 'undefined') window.SEGMANTA__USER_METADATA = metaData
    }
  } catch (e) {
    console.info(e)
  }
}

let segmantaChecked = 0
function segmantaLoadEvents(surveyId) {
  try {
    const interval = setInterval(() => {
      segmantaChecked += 1
      if (window?.SEG_EMBED_API?.hasCompletedScenarioSurvey === 'function') {
        clearInterval(interval)
      }
      if (segmantaChecked > 5) {
        clearInterval(interval)
        if (process.env.DEQ_COMPLETE_PROFILE_2_0 === surveyId) {
          standardEventForSurveyStart(STATUS_1216, { surveyId })
          LoginStore.setErrorModalCode(STATUS_1216)
        } else {
          standardEventForSurveyStart(STATUS_1215, { surveyId })
          LoginStore.setErrorModalCode(STATUS_1215)
        }
        segmantaChecked = 0
      }
    }, 1000)
  } catch (error) {
    segmantaChecked = 0
  }
}

export const feedbackSurvey = (
  surveyId,
  surveyContextualLoginText,
  isfeedbackSurvey,
) => {
  try {
    segmantaChecked = 0
    segmantaLoadEvents(surveyId)

    if (!storageAvailable('localStorage') || !storageAvailable('sessionStorage')) {
      LoginStore.showCookiesDisabledodal = true
      return
    }

    if (!LoginStore.rewardStatus) {
      LoginStore.showSignInPopup = true
      LoginStore.context = surveyContextualLoginText || loginContextText.featuredPromo
      if (isfeedbackSurvey) {
        LoginStore.openFeedbackSurvey = true
      } else {
        LoginStore.openSurvey = true
      }
      sessionStorage.setItem('surveyId', surveyId)
      localStorage.setItem('open-surveyId', surveyId)
    } else {
    // Below console is required to check in prod if any issue arise
    // eslint-disable-next-line no-console
      if (
        sessionStorage.getItem('digital-coupons') === 'true'
      && process.env.DEQ_COMPLETE_PROFILE_2_0 === surveyId
      ) {
        return
      }
      if (surveyId !== undefined) {
        console.info('surveyId', surveyId)
        tiggerSurveyStartEvent()
        standardEventForSurveyStart(STATUS_1217, { surveyId })
        segmantaMetaData(LoginStore.profileData)
        Segmanta(parseInt(surveyId, 10))
        localStorage.removeItem('open-surveyId')
      }
    }
  } catch (error) {
    if (process.env.DEQ_COMPLETE_PROFILE_2_0 === surveyId) {
      standardEventForSurveyStart(STATUS_1216, { surveyId })
      LoginStore.setErrorModalCode(STATUS_1216)
    } else {
      standardEventForSurveyStart(STATUS_1215, { surveyId })
      LoginStore.setErrorModalCode(STATUS_1215)
    }
  }
}

export const isSurveyCompleted = (surveyId) => new Promise((resolve) => {
  if (LoginStore.rewardStatus && surveyId) {
    segmantaMetaData(LoginStore.profileData)
    window.SEG_EMBED_API.hasCompletedScenarioSurvey(
      parseInt(surveyId, 10),
      resolve,
      true,
    )
  } else resolve(false)
})

export const isSegmantaLoaded = (callback) => {
  if (
    typeof window !== 'undefined'
    && typeof window.SEG_EMBED_API !== 'undefined'
    && typeof window.SEG_EMBED_API.hasCompletedScenarioSurvey === 'function'
  ) {
    callback()
  } else {
    setTimeout(() => {
      isSegmantaLoaded(callback)
    }, 100)
  }
}

export const loadDEQSurvey = (surveyID) => {
  isSegmantaLoaded(() => {
    if (LoginStore.profileData) segmantaMetaData(LoginStore.profileData)
    feedbackSurvey(surveyID)
  })
}

export const handleLoadCompleteProfileSurvey = async () => {
  const surveyEligible = await handleCheckRewardTransactionType(
    profileTypeCodes,
  )
  if (!surveyEligible) {
    LoginStore.completeProfile20SurveyTaken = true
    return true
  }
  isSegmantaLoaded(async () => {
    const completeProfileStatus = await isSurveyCompleted(process.env.DEQ_COMPLETE_PROFILE_2_0)
    if (!completeProfileStatus) {
      feedbackSurvey(process.env.DEQ_COMPLETE_PROFILE_2_0)
    }
  })
  return false
}

export const handleLoadCompleteProfile20OrBuildYourProfileSurvey = async () => {
  const completeProfileSurveyEligible = await handleCheckRewardTransactionType(
    profileTypeCodes,
  )
  if (completeProfileSurveyEligible) {
    isSegmantaLoaded(async () => {
      const completeProfileStatus = await isSurveyCompleted(process.env.DEQ_COMPLETE_PROFILE_2_0)
      if (!completeProfileStatus) feedbackSurvey(process.env.DEQ_COMPLETE_PROFILE_2_0)
    })
  } else {
    const tuayRewardRewardCheck = await aimiaStore?.issuedRewards.filter(
      (item) => item?.rewardTypeExternalReference === 'Sweep_YO_PG',
    )
    if (tuayRewardRewardCheck?.length === 0) {
      isSegmantaLoaded(async () => {
        const buildYourProfileStatus = await isSurveyCompleted(process.env.DEQ_BUILD_YOUR_PROFILE)
        if (!buildYourProfileStatus) feedbackSurvey(process.env.DEQ_BUILD_YOUR_PROFILE)
      })
    }
  }
}

export const isCampaignActive = (startAndEndDate) => {
  const presentDate = new Date(formatDateWithHyphen(new Date()))
  const messageStartDate = new Date(
    formatDateWithHyphen(startAndEndDate?.startDate),
  )
  const messageEndDate = new Date(
    formatDateWithHyphen(startAndEndDate?.endDate),
  )
  if (presentDate >= messageStartDate && presentDate <= messageEndDate) {
    return true
  }
  return false
}

export const isCampaignOver = (expiryDate) => {
  if (!expiryDate) return false
  const campaignExpiryTime = (new Date(expiryDate).getTime()) / 1000
  const currentTime = (new Date().getTime()) / 1000
  const campaignOver = campaignExpiryTime - currentTime < 0
  return campaignOver
}

export const getMasterSurveyID = async (
  featuredSurvey,
  monthlyPromoRewardId,
  mosStartAndEndDate,
) => {
  let surveyID
  const mosDateActive = isCampaignActive(mosStartAndEndDate)
  if (featuredSurvey || mosDateActive) {
    if (
      !aimiaStore.redeemRewards
      && sessionStorage.getItem('PromotionalSurvey') === 'featured_survey'
    ) surveyID = process.env.DEQ_MASTER_PROMO
    const monthlyFeaturedPromosurveyAvalied = aimiaStore.redeemRewards
      && aimiaStore.redeemRewards.filter(
        (item) => item.rewardTypeExternalReference === monthlyPromoRewardId,
      )
    if (
      monthlyFeaturedPromosurveyAvalied
      && monthlyFeaturedPromosurveyAvalied.length === 0
    ) surveyID = process.env.DEQ_MASTER_PROMO
    else surveyID = process.env.DEQ_MASTER
  } else if (sessionStorage.getItem('PromotionalSurvey') === '30_Day_Reac') surveyID = process.env.DEQ_POINTS_EARNING_ACTIVATION
  else if (
    sessionStorage.getItem('PromotionalSurvey') === 'Promo_Survey_Fandango') surveyID = process.env.DEQ_BRAND_SAVER
  else if (
    sessionStorage.getItem('PromotionalSurvey') === 'Survey_R45') surveyID = process.env.DEQ_POINTS_EARNING_REACTIVATION
  else surveyID = process.env.DEQ_MASTER
  return surveyID
}

export async function rewardMessageAfterSurveyCompletion(
  siteSettings,
  updateBalance,
) {
  if (LoginStore.rewardStatus === true && !surveyGoalReachedCallbackLoaded) {
    isSegmantaLoaded(async () => {
      surveyGoalReachedCallbackLoaded = true
      window.SEG_EMBED_API.on('SURVEY_GOAL_REACHED', async (scenarioid) => {
        sessionStorage.removeItem('memberHistory')
        sessionStorage.removeItem('PromotionalSurvey')
        if (
          scenarioid
          && scenarioid.toString() === process.env.DEQ_SURVEY_IOH_TAPJOY
        ) {
          triggerSurveyCompleteEvent('ioh-video-gating')
        } else if (
          scenarioid
          && scenarioid.toString() === process.env.DEQ_COMPLETE_PROFILE_2_0
        ) {
          // await issueInteractionApi('Complete_Profile_2')
          LoginStore.completeProfile20SurveyTaken = true
          triggerSurveyCompleteEvent()
        } else if (
          scenarioid
          && scenarioid.toString() === process.env.DEQ_BUILD_YOUR_PROFILE
        ) {
          // await issueInteractionApi('Internal_TUAY_2')
          LoginStore.buildYourProfileSurveyTaken = true
          triggerSurveyCompleteEvent()
        } else if (
          scenarioid
          && scenarioid.toString()
            === process.env.DEQ_3_BUILD_YOUR_PROFILE_3_GIFT_CARD
        ) {
          // await issueInteractionApi('Internal_TUAY_2')
          LoginStore.buildYourProfileSurveyTaken = true
          triggerSurveyCompleteEvent()
        } else {
          triggerSurveyCompleteEvent()
        }
        aimiaStore.pointsHistory = null
        setTimeout(async () => {
          await aimiaStore.fetchMemberHistoryAction()
        }, 1000)
        setTimeout(async () => {
          clearSessionCharityPoints()
          if (updateBalance) fetchPointsBalanceApi()
          if (siteSettings.headerAlertBoxRightSideContents) {
            fetchPointsBalanceApi()
            aimiaStore.fetchCharityLevelPoints(true)
          }
        }, 1500)
      })
    })
  }
}
